


















import Vue from "vue";
import Component from "vue-class-component";
import { PurchaseGroupApi } from "@/api/PurchaseGroupApi";
import { PurchaseGroup } from "@/context/purchase_groups/model/domain";

@Component({})
export default class PurchaseGroupPage extends Vue {
  breadcrumbContent(idx, path) {
    // if (this.$route?.matched[idx]?.meta?.breadcrumb) {
    //   return this.$route?.matched[idx]?.meta?.breadcrumb;
    // } else {
    //   let pg: PurchaseGroup = await PurchaseGroupApi.findOne(idx);
    //   return pg.name;
    // }
    return this.$route?.matched[idx]?.meta?.breadcrumb || path;
  }

  get breadcrumbs(): string[] {
    let pathArray: string[] = this.$route.path.split("/");
    pathArray.shift();
    let breadcrumbs: { path: string; href: string; text: string }[] = [];
    for (let i = 0; i < pathArray.length - 1; i++) {
      let path = pathArray[i];
      let href = breadcrumbs[i - 1] ? "/#/" + breadcrumbs[i - 1].path + "/" + path : "/#/" + path;
      breadcrumbs.push({
        path: path,
        href: href,
        text: this.breadcrumbContent(i, path)
      });
    }
    let breadcrumbsOld: string[] = pathArray.reduce((breadcrumbArray, path, idx) => {
      breadcrumbArray.push({
        path: path,
        href: breadcrumbArray[idx - 1]
          ? "/#/" + breadcrumbArray[idx - 1].path + "/" + path
          : "/#/" + path,
        text: this.breadcrumbContent(idx, path)
      });
      return breadcrumbArray;
    }, []);
    console.log(breadcrumbs);
    console.log(breadcrumbsOld);
    return breadcrumbsOld;
  }
}
